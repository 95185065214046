@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.carrier-highlights {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--rc-space-1);
  align-self: stretch;
  padding-top: 1rem;
  margin-top: var(--rc-space-1);
}

.carrier-highlights__row {
  display: flex;
  align-items: center;
  gap: var(--rc-space-2);
  align-self: stretch;
}

.carrier-highlights__logo img {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: var(--rc-space-1);
}

.carrier-highlights__logo__usps img {
  width: 2.4rem;
}

.carrier-highlights__logo__ups {
  width: 2rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.carrier-highlights__text {
  @include rc-text.utility('350');
  color: var(--rc-color-text-secondary);
}
